.ant-table-thead .ant-table-cell {
    background-color: #1CB6E4
    !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: small;
    border-bottom: none !important;
  }

    .ant-table-thead .ant-table-cell:first-child {
        background-color: #fff !important;
        color: #fff !important;
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        border-top-left-radius: 16px !important;
    }

    .ant-table-thead .ant-table-cell:last-child {
        background-color: #fff !important;
        color: #fff !important;
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        border-top-right-radius: 16px !important;
    }

    .ant-table-row {
        color: black !important;
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        font-size: small;
    }