.ant-table-thead .ant-table-cell {
    background-color: #039ECC !important;
    color: #fff !important;
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
}

.ant-table-row {
    color: black !important;
    font-weight: regular !important;
    font-family: 'Montserrat', sans-serif;
}

.Mui-disabled {
    color: #039ECC !important;
}

.MuiSelect-root {
    color: red !important;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.MuiSelect-select {
    color: black !important;
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif;
}

.inter-select .MuiSelect-select {
    color: #039ECC !important;
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif;
}

.MuiMenuItem-root {
    color: black !important;
    font-weight: regular !important;
    font-family: 'Montserrat', sans-serif;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../../../public/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.my-swal {
    z-index: 9999 !important;
  }