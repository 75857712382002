.requests-table {
    margin-bottom: 1rem;
  }
  
  .requests-table .ant-table {
    font-size: 14px;
  }

  .requests-table .ant-table-thead .ant-table-cell:first-child {
    background-color: #1CB6E4 !important;
    color: #fff !important;
    font-weight: bold !important;
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    border-top-left-radius: 16px !important;
  }

  .requests-table .ant-table-thead .ant-table-cell:last-child {
    background-color: #1CB6E4 !important;
    color: #fff !important;
    font-weight: bold !important;
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    border-top-left-radius: 16px !important;
  }
  
  .requests-table .ant-table-thead > tr > th {
    background-color: #1CB6E4 !important;
    color: #fff !important;
    font-weight: bold !important;
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    border-top-left-radius: 16px !important;
  }
  
  .requests-table .ant-table-tbody > tr > td {
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
  }

  .requests-table .ant-table-row {
    color: black !important;
    font-weight: bold !important;
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    font-size: small;
  }

  .status-in-progress {
    color: #9A9B9B;
  }
  
  .status-approved {
    color: #0B9C08;
  }
  
  .status-rejected {
    color: #FF2323;
  }
  
  .status-default {
    color: black;
  }

  .status-done {
    color: #1CB6E4;
  }

  .status-revision {
    color: #FFD100;
  }

.ant-input {
  background-color:rgba(176, 183, 185, 0.01);
  }

.ant-input-suffix {
  background-color: none;
  }

.ant-input-placeholder {
  background-color: rgba(176, 183, 185, 0.22);
  }

.ant-input-affix-wrapper {
  background-color: rgba(176, 183, 185, 0.22);
  }

.ant-btn {
  background-color: rgba(176, 183, 185, 0.22);
  }

