.ant-table-thead .ant-table-cell {
    background-color: #1CB6E4
    !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: small;
    border-bottom: none !important;
  }

    .ant-table-thead .ant-table-cell:first-child {
        background-color: #fff !important;
        color: #fff !important;
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        border-top-left-radius: 16px !important;
    }

    .ant-table-thead .ant-table-cell:last-child {
        background-color: #fff !important;
        color: #fff !important;
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        border-top-right-radius: 16px !important;
    }

    .ant-table-row {
        color: black !important;
        font-weight: bold !important;
        font-family: 'Montserrat', sans serif !important;
        font-size: small;
    }
   
.my-swal {
    border-radius: 0vh;
    }    


.my-swal .swal2-popup {
        border-radius: 4vh;
    }

    .my-swal-confirm-button {
        border-radius: 2vh !important;
        background-color: #E62530;
        color: #fff !important;
        font-weight: bold !important;
        width: 160px !important;
      }
      
      .my-swal-cancel-button {
        border-radius: 2vh !important;
        background-color: #CACACA;
        color: #000 !important;
        font-weight: bold !important;
        width: 160px !important;
      }

.my-swal-actions {
        display: flex;
        justify-content: center;
        gap: 32px;
      }

.my-swal .swal2-popup .swal2-content {
        font-family: 'Montserrat', sans serif;
        font-size: small;
    }